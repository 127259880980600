// ** React Imports
import { useEffect, useState, createContext } from 'react'

// ** Create Context
const DarkThemeColors = createContext()

const DarkThemeContext = ({ children }) => {
   // ** State
   const [ selectedTheme , setSelectedTheme] = useState();

   const [ reactSelectedTheme , setReactSelectedTheme] = useState();
    //** ComponentDidMount
    useEffect(() => {
      if (window !== 'undefined') {
        setSelectedTheme(JSON.parse(localStorage.getItem('skin')))
      }
    }, [setSelectedTheme])

 

  return <DarkThemeColors.Provider value={{ selectedTheme, setSelectedTheme , reactSelectedTheme , setReactSelectedTheme }}>{children}</DarkThemeColors.Provider>
}

export { DarkThemeColors, DarkThemeContext }
